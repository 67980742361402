/* @tailwind base;
@tailwind components;
@tailwind utilities; */

html, body {
    overflow-x: hidden;
}

#footer-disclaimer{
	margin-bottom: 50px;
}

.text-blue-600 {
	--tw-text-opacity: 1;
	color: rgb(28 100 242 / var(--tw-text-opacity));
}

.border-blue-600 {
	--tw-border-opacity: 1;
	border-color: rgb(28 100 242 / var(--tw-border-opacity));
}

.text-black {
	color: #000000;
}


.bg-dark {
	background: #000000;
}


.bg-dark-100 {
	background: #171717;
}

.black-theme .select2-container--default .select2-selection--multiple .select2-selection__choice {
	color: #000;
}

.primary-background,
.darken-background {
	background: #222222;
}

body.black-theme {
	background: #000000;
}

body.black-theme .page-footer {
	background-color: #000000;
}

body.black-theme .page-footer .footer-copyright {
	background-color: #000000;
}


.card {
	background: #424242;
	color: #fff;

}

.black-theme .card {
	background: #171717;
	border: 1px solid #656565;
	color: #fff;
}

.black-theme label {
	color: #fff;
}

.black-theme .bg-dark {
	background: #222222;
}

.black-theme input {
	color: #fff;
}

.d-none {
	display: none !important;
}

/* #news-ticker input,
#news-ticker input:focus {
	border-bottom: none !important;
	height: auto !important;
	color: #000 !important;
	box-shadow: none !important
} */

.black-theme label {
	color: #fff;
}

label {
	font-size: 0.8rem;
	color: #9e9e9e;
}

.black-theme article .MuiPaper-root {
	background: #444;
	color: #fff;
}

label {
	color: #000;
}

.black-theme label {
	color: #fff;
}

.bg-theme-dark {
	background: #e5e4e4;
}

.black-theme .bg-theme-dark {
	background: #171717;
}


/* dark-mode.css */
.black-theme .react-select__control {
	background-color: #333 !important;
	border-color: #555 !important;
	color: #fff !important;
}

.black-theme .react-select__menu {
	background-color: #333 !important;
	color: #fff !important;
}

.black-theme .react-select__option {
	background-color: #333 !important;
	color: #fff !important;
}

.black-theme .react-select__option--is-focused {
	background-color: #555 !important;
	color: #fff !important;
}

.black-theme .react-select__option--is-selected {
	background-color: #007bff !important;
	color: #fff !important;
}

.black-theme .react-select__single-value {
	color: #fff !important;
}

.black-theme .react-select__input {
	color: #fff !important;
}

#modal {
	z-index: 100;
}

#news-container a {
	color: #007bff;
}


.switch label .lever {
	height: 16px;
	border: 1px solid #fff;
}

.border-gray-100 {
	border-color: #212d3b;
}

.adsprivacy h3 {
	font-size: 25px;
	font-weight: 700;
}

.adsprivacy h2 {
	font-size: 25px;
	font-weight: 700;
	margin-bottom: 10px;
	margin-top: 10px;
}

.adsprivacy p {
	font-size: 20px;
	margin-bottom: 25px;
}

.adsprivacy ol {
	list-style: auto;
	padding-left: 50px;
	margin-bottom: 25px;
}

.termofservices p {
	font-size: 20px;
	margin-top: 10px;
	padding-left: 20px;
}

.termofservices h4 {
	font-size: 23px;
	margin-top: 25px;
}

.termofservices ol {
	list-style: auto;
	padding-left: 50px;
	margin-bottom: 25px;
	font-size: 20px;
}

.termofservices .term-p {

	margin-bottom: 30px;
	font-size: 20px;
}

.termofservices .term-m {
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 20px;
}

.termofservices h3 {
	font-size: 30px;
}

.mobile-bottom-nav {
	display: none;
}

.mobile-flex{
	display: block;
}

.heading-text{
	font-size: 25px;
	margin-bottom: 30px;
}


.cal-input{
	background: #333333;
	border-radius: 5px;
    border-color: #555;
    min-height: 56px;
	font-size : 20px
}

@media only screen and (max-width: 1024px) {

	.container{
		width: 95% !important;
	}
	.mobile-bottom-nav {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		will-change: transform;
		transform: translateZ(0);
		display: flex;
		height: 50px;
		box-shadow: 0 -2px 5px -2px #333;
		background-color: #2b2b2b;
	}

	.mobile-bottom-nav__item {
		flex-grow: 1;
		text-align: center;
		font-size: 12px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.mobile-bottom-nav__item--active {
		color: red;
	}

	.mobile-bottom-nav__item-content {
		display: flex;
		flex-direction: column;
	}

	.mobile-menu-item {
		display: none !important;
	}

	.App{
		margin-bottom: 60px;
	}

	.mobile-container{
		width: 95%;
		margin: 0 auto;
		overflow-x: hidden !important;
	}

	.mobile-flex{
		display: flex;
	}

	#content .calculator-result{
		font-size: 17px;
		margin-top: 30px;
	}
}